<template>
  <div class="application">
    <button class="inline-button main-accent edit-button" @click="$emit('edit')"><v-svg h="1.4em" name="edit" /> Modifier</button>
    <app-logo hide-tooltip class="bg-white" :application="application" />
    <div class="name">
      {{ application.name }}
    </div>
    <div class="id">
      id: <code>{{ application.id }}</code>
    </div>
    <div class="project">
      {{ application.project }}
    </div>
    <div class="roles">
      <div class="label">Rôles:</div>
      <div class="list">
        <div v-for="role in application.roles" :key="role" class="role">{{ role }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Application',
    props: {
      application: {
        type: Object,
        required: true
      }
    },
    emits: ['edit'],
    setup() {}
  }
</script>

<style lang="scss" scoped>
  .application {
    background: hsla(var(--hsla-black), 0.05);
    padding: 16px;
    border-radius: 16px;
    position: relative;
    .edit-button {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    .name {
      @extend %font-title-4;
      margin-top: 12px;
    }
    .id {
       @extend %font-caption-2;
       color: hsla(var(--hsla-black), 0.5);
    }
    .project {
      color: hsla(var(--hsla-black), 0.5);
    }
    .roles {
      margin-top: 8px;
      .label {
        color: hsla(var(--hsla-black), 0.5);
        margin-bottom: 4px;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        .role {
          @extend %font-caption-2;
          font-weight: bold;
          padding: 6px 4px;
          background: hsla(var(--hsla-black), 0.05);
          border-radius: 4px;
          color: var(--color-second-accent);
          margin-bottom: 4px;
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }
</style>
