<template>
  <side-popup @close="$emit('close')">
    <div class="head">
      <input v-model.trim="name" placeholder="Titre de l'application" type="text" class="input-title">
      <button class="inline-button destructive" @click="$emit('close')"><v-svg name="close" />Fermer</button>
    </div>
    <div class="content">
      <div class="label align-center">Projet gcp</div>
      <input v-model.trim="project" type="text" class="main-input white" placeholder="Projet">
      <div class="label align-center">id</div>
      <input v-model.trim="id" :disabled="!!application" type="text" class="main-input white" placeholder="Id" @keydown.space.prevent>
      <div class="label">Smart Galaxy</div>
      <v-checkbox v-model="galaxy" />
      <template v-if="galaxy">
        <div class="label align-center">URL {{ env }}</div>
        <input v-model.trim="url" type="text" class="main-input white" placeholder="URL" @keydown.space.prevent>
        <div class="label align-center">Description</div>
        <input v-model.trim="description" type="text" class="main-input white" placeholder="Description">
      </template>
      <div class="label align-center">Centre d'aide</div>
      <input v-model.trim="documentation" type="text" class="main-input white" placeholder="URL centre d'aide">
      <div class="label">Icône</div>
      <div class="flex">
        <div class="logo">
          <app-logo :application="appForIcon" class="bg-grey" />
          <div v-if="icon" class="remove-icon" @click="icon = ''"><v-svg h="14px" name="close" /></div>
        </div>
        <input id="file-icon" class="input-icon" type="file" accept="image/png, image/jpeg" @change="fileUploaded">
        <label for="file-icon"> <v-svg name="upload" h="1.3em" /> Importer</label>
      </div>
      <div class="label">Rôles</div>
      <div class="roles">
        <template v-for="role in roles" :key="role">
          <div class="name">{{ role }}</div>
          <div class="remove" @click="removeRole(role)"><v-svg name="remove" /></div>
        </template>
        <input v-model.trim="addingRole" type="text" class="main-input" placeholder="Nom du rôle à ajouter" @keydown.enter="addRole">
        <button class="inline-button main-accent" @click="addRole"> <v-svg name="add" /> Ajouter</button>
      </div>
      <div class="label">Product Owner</div>
      <div class="product-owner">
        <search-user v-if="!productOwner" @select="setProductOwner" />
        <div v-else class="user">
          <div class="name">{{ productOwner.displayName }} </div>
          <div class="email">{{ productOwner.mail }}</div>
        </div>
        <button v-if="productOwner" class="cancel inline-button destructive" @click="productOwner = null"><v-svg name="close" /></button>
      </div>
      <div class="label">Responsables</div>
      <div class="responsibles">
        <div />
        <div>Notif.</div>
        <div>Cache</div>
        <div>Habilit.</div>
        <div />
        <template v-for="(responsible, i) in responsibles" :key="`user#${i}`">
          <div class="user">
            <div class="name">{{ responsible.name }} </div>
            <div class="email">{{ responsible.email }}</div>
          </div>
          <v-checkbox v-model="responsible.Notifications" />
          <v-checkbox v-model="responsible.Caches" />
          <v-checkbox v-model="responsible.Authorizations" />
          <div class="remove" @click="removeResponsible(responsible.email)"><v-svg name="remove" /></div>
        </template>
        <search-user @select="addResponsible" />
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <div class="delete" @click="deleteApplication"><v-svg name="delete" /></div>
        <div class="save" @click="saveApplication"><v-svg name="save" />Enregistrer </div>
      </div>
    </template>
  </side-popup>
</template>

<script>
  import { computed, ref, watch } from 'vue'

  export default {
    name: 'ApplicationEditor',
    props: {
      application: {
        type: Object,
        default: null
      }
    },
    emits: ['close', 'save', 'delete'],
    setup(props, context) {
      
      const name = ref(props.application?.name ?? '')

      const project = ref(props.application?.project ?? '')
      const id = ref(props.application?.id ?? '')

      const galaxy = ref(props.application?.galaxy ?? false)
      const url = ref(props.application?.url ?? '')
      const description = ref(props.application?.description ?? '')

      const documentation = ref(props.application?.documentation ?? '')

      const env = computed(() => process.env.VUE_APP_BUILD_ENV)

      watch(name, (newV, oldV) => {
        if (props.application) return
        const generateId = id => id.replace(/ /gm, '').toLowerCase()
        if (id.value === generateId(oldV)) id.value = generateId(newV)
      })

      const icon = ref(props.application?.icon ?? '')
      const fileUploaded = event => {
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          () => {
            icon.value = reader.result //base64
          },
          false
        )
        reader.readAsDataURL(file)
      }
      const appForIcon = computed(() => (icon.value || name.value ? { icon: icon.value, name: name.value } : undefined))

      const addingRole = ref('')
      const roles = ref(props.application?.roles ?? [])

      const addRole = () => {
        if (addingRole.value) roles.value.push(addingRole.value)
        addingRole.value = ''
      }

      const removeRole = role => {
        roles.value = roles.value.filter(r => r != role)
      }

      const productOwner = ref(props.application?.productOwner)

      const setProductOwner = po => {
        if (po) {
          const { mail, displayName, uid } = po
          productOwner.value = { mail, displayName, uid }
        } else {
          productOwner.value = null
        }
      }

      const responsibles = ref(props.application?.responsibles ? JSON.parse(JSON.stringify(props.application.responsibles)) : [])

      const removeResponsible = email => {
        responsibles.value = responsibles.value.filter(r => r.email !== email)
      }

      const addResponsible = user => {
        responsibles.value.push({
          email: user.mail,
          name: user.displayName,
          Notifications: false,
          Caches: false,
          Authorizations: false
        })
      }

      //SAVE/DELETE APP
      const application = computed(() => ({
        name: name.value,
        project: project.value,
        id: id.value,
        galaxy: galaxy.value,
        url: url.value,
        description: description.value,
        documentation: documentation.value,
        icon: icon.value,
        roles: roles.value,
        responsibles: responsibles.value,
        productOwner: productOwner.value
      }))

      const saveApplication = () => {
        if (!!name.value && !!project.value && !!id.value) {
          context.emit('save', application.value)
        }
      }

      const deleteApplication = () =>  {
        alert("Merci de contacter un dev smart pour supprimer une app")
        // context.emit('delete', application.value)
      }

      return {
        name,
        project,
        id,
        env,
        url,
        galaxy,
        description,
        documentation,
        saveApplication,
        deleteApplication,
        fileUploaded,
        icon,
        appForIcon,
        roles,
        addRole,
        addingRole,
        removeRole,
        productOwner,
        setProductOwner,
        responsibles,
        removeResponsible,
        addResponsible
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    color: hsla(var(--hsla-black), 0.5);
  }
  .align-center {
    align-self: center;
  }
  .head {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 24px;
    .input-title {
      @extend %font-title-3;
      height: 51px;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      width: 100%;
      outline: none;
      padding: 16px;
      &:active,
      &:focus {
        border: 1px solid var(--color-second-accent);
      }
    }
  }
  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    position: relative;
    margin-right: 8px;
    padding-bottom: 64px;
    .flex {
      display: flex;
      align-items: center;
    }
    .logo {
      position: relative;
      margin-right: 8px;
      .remove-icon {
        color: var(--color-white);
        background: var(--color-destructive);
        border-radius: 50%;
        position: absolute;
        right: -4px;
        top: -4px;
        cursor: pointer;
      }
    }
    .input-icon {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      & + label {
        @extend %font-body;
        height: 32px;
        padding: 4px 6px;
        border-radius: 8px;
        background: hsla(var(--hsla-black), 0.05);
        cursor: pointer;
        color: var(--color-second-accent);
        display: flex;
        align-items: center;
        .v-svg {
          margin-right: 0.4em;
        }
      }
    }
    .roles {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 16px;
    }
    .user {
      justify-self: start;
      .name {
        @extend %font-body-bold;
      }
      .email {
        @extend %font-caption-1;
        color: hsla(var(--hsla-black), 0.5);
      }
    }
    .product-owner {
      display: flex;
      align-items: center;
      .search-user {
        width: 100%;
      }
      .cancel {
        margin-left: 8px;
      }
    }
    .responsibles {
      display: grid;
      grid-template-columns: 1fr repeat(4, auto);
      gap: 16px;
      justify-items: center;
      align-items: center;

      .search-user {
        grid-column: 1 / 5;
        width: 100%;
      }
    }
    .remove {
      justify-self: end;
      color: var(--color-destructive);
      cursor: pointer;
    }
  }
  .footer {
    display: grid;
    grid-template-columns: 44px 1fr;
    justify-items: center;
    height: 44px;
    align-items: center;
    color: var(--color-white);
    .delete,
    .save {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 200ms;
    }
    .delete {
      cursor: pointer;
      background: var(--color-destructive);
      &:hover {
        background: var(--color-destructive-darker);
      }
    }
    .save {
      cursor: pointer;
      background: var(--color-second-accent);
      &:hover {
        background: var(--color-second-accent-darker);
      }
    }
  }
</style>
