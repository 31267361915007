<template>
  <div class="applications-view">
    <div class="head">
      <div class="left">
        <input v-model="filter" class="main-input" type="text" placeholder="Rechercher par nom">
        <div class="flex">
          <span>Afficher uniquement la galaxy Smart :</span>
          <v-checkbox v-model="showGalaxy" />
        </div>
      </div>
      <button class="main-button second-accent" @click="openEditor()"> <v-svg h="1.4em" name="add" /> Nouvelle application </button>
    </div>
    <div class="applications">
      <application v-for="app in filteredApps" :key="app.id" :application="app" @edit="openEditor(app)" />
    </div>
  </div>
  <application-editor v-if="editorOpened"
                      :application="editionItem"
                      @delete="deleteApplication"
                      @save="saveApplication"
                      @close="closeEditor" />
</template>

<script>
  import useApplicationStore from '@/store/application.store'
  import Application from '@/components/application/Application'
  import ApplicationEditor from '@/components/application/ApplicationEditor'
  import useModalEditor from '@/mixins/modalEditor'
  import { computed, ref } from 'vue'

  export default {
    name: 'Applications',
    components: { Application, ApplicationEditor },
    setup() {
      const applicationStore = useApplicationStore()
      const { applications } = applicationStore

      const modalEditor = useModalEditor()

      const filter = ref('')
      const showGalaxy = ref(false)

      const filteredApps = computed(
        () => applications.value?.filter(a => (showGalaxy.value ? a.galaxy : true) && a.name.toUpperCase().includes(filter.value.toUpperCase())) ?? []
      )

      const saveApplication = async application => {
        if (modalEditor.editionItem.value) await applicationStore.updateApplication(application)
        else await applicationStore.addApplication(application)
        modalEditor.closeEditor(true)
      }

      const deleteApplication = async notification => {
        if (confirm(`Supprimer l'application ${notification.name} ?`)) await applicationStore.deleteApplication(notification)
        modalEditor.closeEditor(true)
      }

      return {
        filteredApps,
        filter,
        showGalaxy,
        saveApplication,
        deleteApplication,
        ...modalEditor
      }
    }
  }
</script>

<style lang="scss" scoped>
  .applications-view {
    padding: 2rem;
    overflow: auto;
    .head {
      display: flex;
      justify-content: space-between;
      input {
        width: 400px;
      }
      .left {
        display: flex;
        align-items: center;
        > * {
          margin-right: 2rem;
        }
      }
      .flex {
        display: flex;
        align-items: center;
      }
    }
    .applications {
      margin: 24px 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 24px;
    }
  }
</style>
